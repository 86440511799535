<template lang="pug">
  
  form( name="invite-form" )
    #close: span#close-letter X
    h2.text-center Plan Your Brews & Clues Adventure
    p.subline.text-center Our tours are available anytime, but your friends won't be. Use this scheduling tool to find a day that works for everyone.

    .row

      .col-sm-5.show-xs
        h3 Choose Dates

        .row
          .col-sm-12.py
            FunctionalCalendar(
              v-model="dates"
              :sundayStart="true"
              :is-multiple-date-picker="true"
              :date-format="'yyyy-mm-dd'"
            )
        
        .row
          .col-sm-3.py
            h4 Selected Dates
          
          .col-sm-9.py
            .row
              div.col-xs-4.text-center( v-for="date in dates.selectedDates" ): .date
                .day {{ parseDate( date.date, 'ddd' ) }}
                p.day-num {{ parseDate( date.date, 'D' ) }}
                p.month {{ parseDate( date.date, 'MMM' ) }}
  
      .col-sm-7
        h3 Event Details

        .row
          .col-sm-6.py
            label Your Name
            input( v-model="name" type="text" required )

          .col-sm-6.py
            label Your Email
            input( v-model="email" type="email" required )
        
          .col-sm-12.py
            label Invitation
            input( v-model="invitation" type="text" placeholder="Let's do a Brews & Clues tour!" required )

          .col-sm-12.py
            label Invite Your Friends
            vue-tags-input(
              v-model="emailTag"
              :tags="inviteFriendsEmails"
              :add-on-key="[13, ' ']"
              placeholder="Add Email"
              @tags-changed="newTags => inviteFriendsEmails = newTags"
            )
        
        .row
          .col-sm-12.py
            h4 Notifications
            label
              input( v-model="notifications" type="checkbox" )
              | Send me an email when someone votes or comments on the event
        
        .row.hidden-xs
          .col-sm-12.text-center.py
            button(
              type="submit"
              @click.prevent="submitForm"
            ) Invite Your Friends - Unlock 15% Off
      
      .col-sm-5
        h3.hidden-xs Choose Dates

        .row.hidden-xs
          .col-sm-12.py
            FunctionalCalendar(
              v-model="dates"
              :sundayStart="true"
              :is-multiple-date-picker="true"
              :date-format="'yyyy-mm-dd'"
            )
        
        .row.hidden-xs
          .col-sm-3.py
            h4 Selected Dates
          
          .col-sm-9.py
            .row
              div.col-xs-4.text-center( v-for="date in dates.selectedDates" ): .date
                .day {{ parseDate( date.date, 'ddd' ) }}
                p.day-num {{ parseDate( date.date, 'D' ) }}
                p.month {{ parseDate( date.date, 'MMM' ) }}
        
        .row.show-xs
          .col-sm-12.text-center.py
            button(
              type="submit"
              @click.prevent="submitForm"
            ) Invite Your Friends - Unlock 15% Off



</template>

<script>
import moment from 'moment'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import VueTagsInput from '@johmun/vue-tags-input'
import { FunctionalCalendar } from 'vue-functional-calendar'

export default {
  name: 'InviteForm',
  components: {
    FunctionalCalendar, VueTagsInput
  },

  props: {
    notifyInvitesSent: {
      type: Function,
      required: true
    }
  },
  
  data() {
    return {
      name: null,
      email: '',
      invitation: null,
      emailTag: '',
      inviteFriendsEmails: [],
      notifications: true,
      dates: {},
    }
  },

  methods: {
    parseDate(date, format) { 
      // Manual fix on date format
      let [ year, month, day ] = date.split( '-' )
      
      // Set two digits
      if( parseInt( month ) < 10 ) month = `0${month}`
      if( parseInt( day ) < 10 ) day = `0${day}`

      let newDate = new Date( `${year}-${month}-${day}` )
      return moment( newDate ).utc().format( format )
    },

    submitForm() {
      setTimeout( () => {
        // Quick validations
        if( !this.name || !this.email || !this.inviteFriendsEmails.length ) {
          Swal.fire({
            title: 'Required fields missing!',
            text: 'In order to continue, you must fill out all fields.',
            imageUrl: 'https://www.brewsandclues.com/wp-content/uploads/2019/12/404_Character.jpg',
            confirmButtonText: 'Got it!'
          })
          return
        }
        
        if( !this.dates || !this.dates.selectedDates || !this.dates.selectedDates.length ) {
          Swal.fire({
            title: 'No dates selected!',
            text: 'In order to continue, you must select at least one date to invite your friends.',
            imageUrl: 'https://www.brewsandclues.com/wp-content/uploads/2019/12/404_Character.jpg',
            confirmButtonText: 'Got it!'
          })
          return
        }

        let data = {
          creator: {
            allowNotifications: this.notifications,
            name: this.name,
            email: this.email
          },
          title: `${this.name}'s Brews & Clues Tour`,
          description: this.invitation,
          emails: this.inviteFriendsEmails.map( email => {
            return { email: email.text }
          }),
          dates: this.dates.selectedDates.map( date => {
            let parsed = moment( this.parseDate( date.date, 'YYYY-MM-DD' ) ).toISOString()
            return parsed
          })
        }
        
        axios.post( 'https://rallly.sequential.tech/api/event', data )
        .then( resp => {
          // Handle success, bump to next form
          this.notifyInvitesSent()
        })
        .catch( err => {
          // Notify user
          Swal.fire({
            text: 'Uh oh! We found an error creating your event. Make sure you fill out every field and choose at least 1 available date.',
            imageUrl: 'https://www.brewsandclues.com/wp-content/uploads/2019/12/404_Character.jpg',
            confirmButtonText: 'Close'
          })
          return
        })
      }, 50)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  li.ti-new-tag-input-wrapper {
    padding: 0 !important;
  }

  #app input[type=checkbox] {
    display: block;
    float: left;
    clear: none;
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 15px;
  }
</style>
