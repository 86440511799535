<template lang="pug">
  
  div
    #close: span#close-letter X
    h2.text-center Buy Your Team Ticket Today—Use It Anytime
    p.subline.text-center Order in the next 5 minutes using the discount code <strong>GOTIME15</strong> to unlock 15% off your purchase.

    .row
      .col-sm-6.text-center
        img( src="https://www.brewsandclues.com/wp-content/uploads/2020/03/happy-character-loop.gif" style="max-width:100%;" )
        h3 Let's Get This Party Started
        p Your friends have been invited to join you on a Brews & Clues tour.
      
      .col-sm-6
        countdown( :time="300000" :auto-start="startTimer" ): template( slot-scope="props" )
          p.text-center: i.time-countdown {{ props.minutes }}:{{ props.seconds < 10 ? 0 : null }}{{ props.seconds }}
        
        form( name="tour-click-through" ): .row
          .col-sm-12.py.relative
            label Choose your tour location
            select( name="tour" v-model="tour" )
              option( value="/tour/boston-freedom-trail/" ) Boston, MA - Downtown
              option( value="/tour/burlington-church-street/" ) Burlington, VT
              option( value="/tour/san-antonio-river-walk/" ) San Antonio, TX
              option( value="/tour/austin-sixth-street/" ) Austin, TX
              option( value="/tour/boston-fenway/" ) Boston, MA - Fenway
              option( value="/tour/philadelphia-old-city/" ) Philadelphia, PA
              option( value="/tour/baltimore-inner-harbor/" ) Baltimore, MD
              option( value="/tour/washington-dc-capitol-white-house/" ) Washington, D.C.
            .chevron-down &#x25BC;
          
          .col-sm-12.py.relative
            label Choose your team size
            select( name="size" v-model="size" )
              option( value="?attribute_size=Small+%281-5+people%29" ) $44.99 - Small (1-5 people)
              option( value="?attribute_size=Medium+%286-10+people%29" ) $59.99 - Medium (6-10 people)
              option( value="?attribute_size=Large+%2811-15+people%29" ) $74.99 - Large (11-15 people)
              option( value="?attribute_size=Unlimited" ) $99.99 - Unlimited
            .chevron-down &#x25BC;
          
          .col-sm-12.py.text-center
            a.button(
              :href="tourLink"
            ) Buy Your Ticket Now

</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'TourPurchase',
  
  components: {
    countdown: VueCountdown
  },
  
  props: {
    startTimer: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    tourLink() {
      return `${this.tour}${this.size}`
    }
  },
  
  data() {
    return {
      tour: '/tour/boston-freedom-trail/',
      size: '?attribute_size=Small+%281-5+people%29'
    }
  },

  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .relative {
    position: relative;
  }
  .chevron-down {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
</style>
