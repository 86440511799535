<template>
  <div id="app">
    <div class="app-wrap" v-show="!invitesSent">
      <InviteForm :notify-invites-sent="notifyInvitesSent" />
    </div>
    <div class="app-wrap" v-show="invitesSent">
      <TourPurchase :start-timer="startTimer" />
    </div>
  </div>
</template>

<script>
import InviteForm from './components/InviteForm.vue'
import TourPurchase from './components/TourPurchase.vue'

export default {
  name: 'App',
  components: {
    InviteForm, TourPurchase
  },

  data() {
    return {
      invitesSent: false,
      startTimer: false
    }
  },

  methods: {
    notifyInvitesSent() {
      this.invitesSent = true
      this.startTimer = true
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.5);
  top: 0px;
  left: 0px;
  padding: 100px 0px;
  
  .app-wrap {
    background: white;
    border-radius: 10px;
    padding: 20px 40px;
    max-width: 1100px;
    margin: auto;
    position: relative;
  }

  #close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 23px;

    span {
      padding: 5px 10px;
      border-radius: 50px;
      border: 1px solid;
    }
  }

  #close:hover {
    cursor: pointer;
  }
  
  .text-center {
    text-align: center;
  }

  .py {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }

  input, select {
    width: 100%;
    font-size: 16px;
    padding: 15px 0px;
    border-radius: 4px;
    border-color: #ccc;
    border-width: 1px;
    text-indent: 15px;
  }

  .col-xs-4 .date {
    border: 1px solid #dfdfdf;
    border-radius: 10px;

    .day {
      font-size: 15px;
      width: auto;
      max-width: 40px;
      margin-right: auto;
      margin-left: auto;
      margin-top: -9px;
      background: white;
      text-transform: uppercase;
      color: #a7a7a7;
    }

    .day-num {
      font-size: 30px;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    .month {
      margin-top:5px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .vue-tags-input {
    width: 100%;
    max-width: 1000px;

    .ti-input {
      padding: 0px;
    }
  }

  .show-xs {
    display: none;
  }
}

@media( max-width: 496px ) {
  #app {
    padding-top: 20px;
    padding-bottom: 20px;

    .row div.vfc-arrow-left, .row div.vfc-arrow-right {
      width: 12px !important;
    }

    .row div.vfc-arrow-right {
      margin-left: auto;
    }

    .row div.vfc-content {
      width: auto !important;
    }

    #close {
      font-size: 17px;
    }

    .app-wrap {
      width: 87%;
      height: 91%;
      padding: 15px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .date {
      margin-bottom: 25px;
    }

    .row {
      margin-left: 0px;
      margin-right: 0px;
      div {
        width: 100% !important;
      }
    }

    .hidden-xs {
      display: none;
    }

    .show-xs {
      display: block;
    }
  }
}
</style>
